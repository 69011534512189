import React from 'react';
import classNames from 'classnames';
import {Button, ButtonPriority, PaddingModes} from 'wix-ui-tpa/cssVars';
import {classes, cssStates} from './CategoryListItem.st.css';
import {ICategoryListItem} from '../../../../types/category';
import {useCategories} from '../../../hooks/useCategories';

type CategoryListItemProps = {
  dataHook?: string;
  className?: string;
  category: ICategoryListItem;
  text: string;
};

export const CategoryListItem: React.FC<CategoryListItemProps> = ({
  dataHook,
  category,
  text,
  className,
}: CategoryListItemProps) => {
  const {currentCategory, navigateToCategory} = useCategories();
  const isActive = currentCategory.id === category.id;

  return (
    <li className={className}>
      <Button
        as="a"
        paddingMode={PaddingModes.explicitPaddings}
        priority={ButtonPriority.secondary}
        data-hook={dataHook}
        className={classNames(classes.root, cssStates({activeCategory: isActive}))}
        href={category.categoryUrl}
        onClick={() => navigateToCategory(category.id)}>
        {text}
      </Button>
    </li>
  );
};
